import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import platform from 'platform-detect';
import Intro from './Intro';
import Home from './Home';
import Frens from './Frens';
import Tasks from './Tasks';
import Stats from './Stats';
import NotFound from './NotFound';
import Modal from './components/UI/Modal';
import Button from './components/UI/Button';
import api from './functions/api';
import { ReactComponent as MainIcon } from './assets/icons/main.svg';
import { ReactComponent as FrensIcon } from './assets/icons/frens.svg';
import { ReactComponent as TasksIcon } from './assets/icons/tasks.svg';
import { ReactComponent as StatsIcon } from './assets/icons/stats.svg';
import './App.css';

export const App = () => {
	let telegramApi = window.Telegram.WebApp;
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [userPlatform] = useState(platform.ios ? 'ios' : platform.android ? 'android' : null);
	const [user, setUser] = useState(null);
	const [frens, setFrens] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [modal, setModal] = useState({ current: null, params: '' });

	const onModal = (current = null, params = null) => {
		setModal({ current, params });
	};

	const onRedirect = (path) => {
		navigate(path);
	};

	useEffect(() => {
		telegramApi.expand();
		telegramApi.disableVerticalSwipes();

		if (telegramApi.initDataUnsafe && telegramApi.initDataUnsafe.user) {
			setUser({ ...telegramApi.initDataUnsafe.user });
		} else {
			setUser({ id: 0 });
		}

		i18n.changeLanguage(telegramApi?.initDataUnsafe?.user?.language_code === 'ru' ? 'ru' : 'en');
		onGetUser(telegramApi.initDataUnsafe.user);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	if ((location.pathname !== '/' && !isIntro) || (location.pathname !== '/' && user === null)) {
	// 		setBackButton();
	// 	}
	// }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

	// const setBackButton = (redirect = '/') => {
	// 	const telegramApi = window.Telegram.WebApp;
	// 	const BackButton = telegramApi.BackButton;
	// 	if (redirect) {
	// 		BackButton.show();
	// 		BackButton.onClick(function() {
	// 			onRedirect(redirect);
	// 			BackButton.hide();
	// 		});
	// 	} else {
	// 		BackButton.hide();
	// 	}
	// }

	const onGetUser = (tgUser) => {
		if (telegramApi && telegramApi.initData) {
			api('POST', '/users/get/', {}, {
				"Authorization": telegramApi.initData,
			}).then((event) => {
				if (!event.user.activated) {
					onRedirect('/intro');
				}
				setTimeout(() => {
					setLoading(false);
				}, 600);
				setUser({
					...event.user,
					...tgUser,
				});
			}).catch((err) => {
				setUser({
					"new": false,
					"activated": true,
					"user": 1,
					"steps": 0,
					"balance": 123456,
					"id": 445551469,
					"first_name": "Mike",
					"last_name": "Petrov",
					"username": "mike_petrov",
					"language_code": "en",
					"is_premium": true,
					"allows_write_to_pm": true,
				});
				onModal('failed', 'Oops! It seems you have an unstable internet connection (we recommend disconnecting from weak networks or VPNs). Try to log in again. If the problem repeats - write us in the chat.');
			});
		} else {
			onModal('failed', 'Not use direct link, open telegram mini app: @gigicoin_bot');
		}
	}

	const onGetRefferals = () => {
		api('POST', '/users/rating/', {}, {
			"Authorization": telegramApi.initData,
		}).then((event) => {
			// event.rating.sort((a, b) => b.daily - a.daily);
			setFrens(event.rating);
		});
	}

	const onGetTasks = () => {
		api('POST', '/tasks/get/', {}, {
			"Authorization": telegramApi.initData,
		}).then((event) => {
			setTasks(event.tasks);
		});
	}

	return (
		<>
			{(modal.current === 'success' || modal.current === 'failed') && (
				<Modal
					title={modal.current === 'success' ? t('success') : t('error')}
					icon={`./img/icons/${modal.current === 'success' ? 'check' : 'error'}.svg`}
					onClickClose={onModal}
				>
					<div className="box_text">{modal.params}</div>
				</Modal>
			)}
			{modal.current === 'loading' && (
				<Modal
					title={t('loading')}
					icon='./img/icons/logo.svg'
					onClickClose={onModal}
				>
					<img
						className="loader"
						src="/img/icons/loader.png"
						width={18}
						height={18}
						alt="loader"
					/>
				</Modal>
			)}
			{modal.current === 'introReturn' && (
				<Modal
					title={t('intro_return_title')}
					onClickClose={onModal}
				>
					<Button
						href={modal.params.userPlatform === 'ios' ? 'https://apps.apple.com/us/app/gigi-tracker/id6529547613' : 'https://play.google.com/store/apps/details?id=in.gigico.gigicoinapp'}
						onClick={() => {
							setTimeout(() => { modal.params.setActiveSection(2) }, 1000)
							onModal();
						}}
						style={{ height: '55px' }}
					>{t('intro_return_btn')}</Button>
				</Modal>
			)}
			{modal.current === 'showBadge' && (
				<Modal
					title={modal.params.title}
					icon='./img/icons/check.svg'
					onClickClose={onModal}
				>
					<div className="box_title">{modal.params.text}</div>
					<div className={modal.params.status === 'done' ? "award_icon active" : "award_icon"}>
						<img
							src={modal.params.icon}
							width={200}
							height={200}
							style={{ margin: '0 30px' }}
							alt={modal.params.title}
						/>
					</div>
				</Modal>
			)}
			<main className="main">
				{!isLoading ? (
					<>
						{userPlatform ? (
							<Routes>
								<Route
									path="/intro"
									element={<Intro
										user={user}
										userPlatform={userPlatform}
										onModal={onModal}
									/>}
								/>
								<Route
									path="/"
									element={<Home
										user={user}
									/>}
								/>
								<Route
									path="/frens"
									element={<Frens
										telegramApi={telegramApi}
										user={user}
										frens={frens}
										onGetRefferals={onGetRefferals}
									/>}
								/>
								<Route
									path="/tasks"
									element={<Tasks
										telegramApi={telegramApi}
										user={user}
										onModal={onModal}
										tasks={tasks}
										onGetTasks={onGetTasks}
									/>}
								/>
								<Route
									path="/stats"
									element={<Stats
										telegramApi={telegramApi}
										user={user}
										onModal={onModal}
									/>}
								/>
								<Route
									path="*"
									element={<NotFound
										location={location}
									/>}
								/>
							</Routes>
						) : (
							<div className="loader_block">
								<img
									src='./img/icons/error.svg'
									width={200}
									height={200}
									alt="error"
								/>
								<div className="loader_title">Step to mobile, walker!</div>
							</div>
						)}
					</>
				) : (
					<div className="loader_block">
						<img
							src='./img/icons/fren.svg'
							width={200}
							height={200}
							alt="fren"
						/>
						<div className="loader_title">Step by Step</div>
					</div>
				)}
				{!isLoading && location.pathname !== '/intro' && (
					<div className="tabbar">
						<div className="tabbar_inner">
							{[{
								title: t('tabbar_main'),
								to: '/',
								icon: <MainIcon width={26} height={26} />,
							}, {
								title: t('tabbar_frens'),
								to: '/frens',
								icon: <FrensIcon width={26} height={26} />,
							}, {
								title: t('tabbar_tasks'),
								to: '/tasks',
								icon: <TasksIcon width={26} height={26} />,
							}, {
								title: t('tabbar_stats'),
								to: '/stats',
								icon: <StatsIcon width={26} height={26} />,
							}].map((item) => (
								<Link
									key={item.title}
									className={location?.pathname === item.to ? "tabbar_item active" : "tabbar_item"}
									onClick={() => telegramApi.HapticFeedback.impactOccurred('soft')}
									to={item.to}
									title={item.title}
								>
									{item.icon}
									<span>{item.title}</span>
								</Link>
							))}
						</div>
					</div>
				)}
			</main>
		</>
	);
}
