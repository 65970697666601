import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from './components/UI/Tabs';
import { ReactComponent as StatsIcon } from './assets/icons/stats.svg';

const Stats = ({
	telegramApi,
	user,
	onModal,
}) => {
	const { t } = useTranslation();
	const [section, setSection] = useState('badges');

	return (
		<div className="container" id="stats">
			<div className="title">
				<span>{t('stats')}</span>
				<span>
					<StatsIcon
						width={34}
						height={34}
					/>
				</span>
			</div>
			<Tabs
				value={section}
				onClick={(value) => {
					telegramApi.HapticFeedback.impactOccurred('soft');
					setSection(value);
				}}
				options={['badges', 'stats']}
				style={{ margin: 18 }}
			/>
			{section === 'badges' && (
				<>
					<div className="page_title">{t('daily')}</div>
					<div className="box">
						<div className="box_info">
							<div className="awards_list">
								{[{
									title: t('walker'),
									text: t('walker_text'),
									icon: 'img/icons/awards/days7.png',
									status: 'undone',
								}, {
									title: t('runner'),
									text: t('runner_text'),
									icon: 'img/icons/awards/days30.png',
									status: 'undone',
								}, {
									title: t('runaway'),
									text: t('runaway_text'),
									icon: 'img/icons/awards/days90.png',
									status: 'undone',
								}].map((item) => (
									<div
										key={item.title}
										className="award_item"
										onClick={() => {
											telegramApi.HapticFeedback.impactOccurred('soft');
											onModal('showBadge', item);
										}}
									>
										<div className={item.status === 'done' ? "award_icon active" : "award_icon"}>
											<img
												src={item.icon}
												width={80}
												height={80}
												alt={item.title}
											/>
										</div>
										<div className="award_title">{item.title}</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="page_title">{t('general')}</div>
					<div className="box">
						<div className="box_info">
							<div className="awards_list">
								{[{
									title: t('sprinter'),
									text: t('sprinter_text'),
									icon: 'img/icons/awards/steps10k.png',
									status: user.balance > 10000 ? 'done' : 'undone',
								}, {
									title: t('rapid'),
									text: t('rapid_text'),
									icon: 'img/icons/awards/steps100k.png',
									status: user.balance > 100000 ? 'done' : 'undone',
								}, {
									title: t('marathoner'),
									text: t('marathoner_text'),
									icon: 'img/icons/awards/steps1m.png',
									status: user.balance > 1000000 ? 'done' : 'undone',
								}].map((item) => (
									<div
										key={item.title}
										className="award_item"
										onClick={() => {
											telegramApi.HapticFeedback.impactOccurred('soft');
											onModal('showBadge', item);
										}}
									>
										<div className={item.status === 'done' ? "award_icon active" : "award_icon"}>
											<img
												src={item.icon}
												width={80}
												height={80}
												alt={item.title}
											/>
										</div>
										<div className="award_title">{item.title}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</>
			)}
			{section === 'stats' && (
				<>
					<div className="page_title">{t('overall')}</div>
					<div className="box">
						<div className="box_info">
							<div className="box_title">{t('your_progress')}</div>
							<div className="box_subtitle" style={{ margin: 0 }}>{t('not_enough_data')}</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Stats;
