import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Button from './components/UI/Button';
import { ReactComponent as FrensIcon } from './assets/icons/frens.svg';

const Frens = ({
	telegramApi,
	user,
	frens,
	onGetRefferals,
}) => {
	const { t } = useTranslation();
	const [isCopied, setCopy] = useState(false);

	useEffect(() => {
		onGetRefferals();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="container" id="frens">
			<div className="title">
				<span>{t('frens')}</span>
				<span>
					<FrensIcon
						width={34}
						height={34}
					/>
				</span>
			</div>
			<div className="box">
				<div className="box_info" style={{ textAlign: 'center' }}>
					<img
						src='./img/icons/invite.svg'
						width={50}
						height={50}
						style={{ marginBottom: 12 }}
						alt="icon"
					/>
					<div className="box_title" style={{ fontSize: '14px', color: '#3262f1' }}>{t('frens_title')}</div>
					<div className="box_title">{t('frens_subtitle')}</div>
					<CopyToClipboard
						text={`https://t.me/gigicoin_bot?start=${user?.id}`}
						onCopy={() => {
							setCopy(true);
							setTimeout(() => {
								setCopy(false);
							}, 700);
						}}
					>
						<div
							className="box_title"
							style={{
								marginBottom: 10,
								background: 'var(--background)',
								borderRadius: 14,
								padding: 16,
								height: 46,
								overflow: 'hidden',
								whiteSpace: 'pre',
								fontSize: 14,
								lineHeight: 1,
							}}
						>{`https://t.me/gigicoin_bot?start=${user?.id}`}</div>
					</CopyToClipboard>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					margin: '20px',
				}}
			>
				<CopyToClipboard
					text={`https://t.me/gigicoin_bot?start=${user?.id}`}
					onCopy={() => {
						setCopy(true);
						setTimeout(() => {
							setCopy(false);
						}, 700);
					}}
				>
					<Button
						style={{ marginRight: 12, height: 50 }}
						onClick={() => telegramApi.HapticFeedback.impactOccurred('soft')}
						stretched
					>{isCopied ? t('copied') : t('copy_link')}</Button>
				</CopyToClipboard>
				<Button
					href={`https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`}
					onClick={() => telegramApi.HapticFeedback.impactOccurred('soft')}
					style={{ margin: 0, height: 50 }}
					stretched
				>
					<img
						src='./img/icons/tg_plane.svg'
						width={24}
						height={24}
						style={{ marginRight: 6 }}
						alt="plane"
					/>
					{t('share')}
				</Button>
			</div>
			<div className="page_title">{t('rating')}</div>
			<div className="friends_list">
				{[{
					user: frens.length > 1 ? frens[1] : '',
					badge: '2',
					style: { borderColor: '#B3C4CD', background: '#565E62', width: 60, height: 60, padding: 10 },
					styleBadge: { background: '#B3C4CD' },
				}, {
					user: frens.length > 0 ? frens[0] : '',
					badge: '1',
					style: { borderColor: '#FFB905', background: '#7A5902', width: 80, height: 80, padding: 10 },
					styleBadge: { background: '#FFB905', left: -2, top: -2 },
				}, {
					user: frens.length > 2 ? frens[2] : '',
					badge: '3',
					style: { borderColor: '#F27641', background: '#74391F', width: 60, height: 60, padding: 10 },
					styleBadge: { background: '#F27641' },
				}].map((item) => (
					<a
						key={item.badge}
						className="friend_item"
						href={item.user ?
							item.user.login ? `https://t.me/${item.user.login}` : `tg://user?id=${item.user.social_id}`
						: `https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`}
					>
						<div
							className="friend_icon"
							style={item.user ? { ...item.style, background: '#FFFFFF' } : item.style}
						>
							{item.user ? (
								<img
									src='./img/icons/fren.svg'
									width={35}
									height={35}
									alt="icon"
								/>
							) : '+'}
							<div className="friend_badge" style={item.styleBadge}>{item.badge}</div>
						</div>
						<div className="friend_title">
							{item.user ? (
								item.user.title ? item.user.title : `@${item.user.login}`
							) : t('invite')}
						</div>
						{item.user && (
							<div className="friend_subtitle">{`${item.user.daily} ${t('steps')}`}</div>
						)}
					</a>
				))}
			</div>
			{frens && frens.map((item, index) => ( index > 2 && (
				<a
					key={item.id}
					href={item.login.replace(/[0-9]/g, '') !== 'id' ? `https://t.me/${item.login}` : `tg://user?id=${item.social_id}`}
					className="box box_task"
				>
					<div className="box_icon" style={{ width: 54, height: 54 }}>
						<img
							src='./img/icons/fren.svg'
							width={35}
							height={35}
							alt="icon"
						/>
					</div>
					<div className="box_info">
						<div className="box_title">{`${index + 1}. ${item.title ? item.title : `${t('user')} #${item.login.replace('id', '')}`}`}</div>
						<div className="box_subtitle" style={{ margin: 0 }}>{item.login.replace(/[0-9]/g, '') !== 'id' ? `@${item.login}` : `${item.social_id}`}</div>
					</div>
					<div className="box_icon_after">{item.daily}</div>
				</a>
			)))}
		</div>
	);
};

export default Frens;
