import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import Button from './components/UI/Button';
import { ReactComponent as TasksIcon } from './assets/icons/tasks.svg';
import api from './functions/api';

const Tasks = ({
	telegramApi,
	user,
	onModal,
	tasks,
	onGetTasks,
}) => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		onGetTasks();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onStory = (image = './img/icons/story.png') => {
		telegramApi.HapticFeedback.impactOccurred('soft');
		telegramApi.shareToStory(image, {
			text: `Join with me: https://t.me/gigicoin_bot?start=${user?.id}`,
			// widget_link: `https://t.me/gigicoin_bot?start=${user?.id}`,
		});

		// const canvas = document.createElement('canvas');
		// const backgroundImg = new Image();
		// backgroundImg.onload = () => {
		// 	canvas.width = backgroundImg.width;
		// 	canvas.height = backgroundImg.height;
		// 	const context = canvas.getContext('2d');

		// 	context.drawImage(backgroundImg, 0, 0);

		// 	context.beginPath();
		// 	context.moveTo(canvas.width / 2, 0);
		// 	context.lineTo(canvas.width / 2, canvas.height);

		// 	context.textAlign = 'center';

		// 	context.fillStyle = "#fff";
		// 	context.font = "bold 300px Inter";
		// 	context.fillText('5654', canvas.width / 2, canvas.height / 2);

		// 	context.fillStyle = "rgba(255, 255, 255, 0.9)";
		// 	context.font = "bold 160px Verdana";
		// 	context.fillText('steps', canvas.width / 2, canvas.height / 2 + 180);

		// 	context.fillStyle = "rgba(255, 255, 255, 0.75)";
		// 	context.font = "80px Verdana";
		// 	context.fillText('I\'ve walked today', canvas.width / 2, canvas.height / 2 + 320);

		// 	const base64 = canvas.toDataURL();

		// 	telegramApi.shareToStory(base64, {
		// 		text: `Join with me: https://t.me/gigicoin_bot?start=${user?.id}`,
		// 		// widget_link: `https://t.me/gigicoin_bot?start=${user?.id}`,
		// 	});
		// };
		// backgroundImg.src = './img/icons/story.png';
	};

	return (
		<div className="container" id="tasks">
			<div className="title">
				<span>{t('tasks')}</span>
				<span>
					<TasksIcon
						width={34}
						height={34}
					/>
				</span>
			</div>
			<div className="page_title">{t('general')}</div>
			{tasks && tasks.map((item) => (
				<a
					key={item.id}
					href={item.link !== 'story' ? item.link : '#'}
					className="box box_task"
					onClick={() => {
						if (item.link === 'story') {
							api('POST', '/tasks/get_story/', {}, {
								"Authorization": telegramApi.initData,
							}).then((event) => {
								onStory(event.url);
							});
						}

						onModal('loading');
						setTimeout(() => {
							api('POST', '/tasks/check/', { id: item.id }, {
								"Authorization": telegramApi.initData,
							}).then((event) => {
								onModal();
								onGetTasks();
							});
						}, 4000);
					}}
				>
					<div className="box_icon" style={{ width: 54, height: 54 }}>
						<img
							src={item.icon}
							width={item.size}
							height={item.size}
							alt="icon"
						/>
					</div>
					<div className="box_info">
						<div className="box_title" style={item.reward ? {} : { margin: 0 }}>{item.title[i18n.language]}</div>
						{item.reward && (
							<div className="box_subtitle" style={{ fontWeight: '900', color: '#3262f1' }}>{`+ ${item.reward}`}</div>
						)}
					</div>
					<div className="box_icon_after">
						<img
							src={item.status === 3 ? "./img/icons/check.svg" : "./img/icons/chevron_right.svg"}
							width={20}
							height={20}
							alt="status"
						/>
					</div>
				</a>
			))}
			<div className="page_title">{t('special')}</div>
			{[{
				icon: './img/icons/tasks/fren.svg',
				title: t('task_special1'),
				link: `https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`,
				text: "10 000",
				width: 34,
				height: 34,
				status: user.frens.length >= 1 ? 'done' : 'undone',
			}, {
				icon: './img/icons/tasks/frens3.svg',
				title: t('task_special2'),
				link: `https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`,
				text: "30 000",
				width: 38,
				height: 38,
				status: user.frens.length >= 3 ? 'done' : 'undone',
			}, {
				icon: './img/icons/tasks/frens7.svg',
				title: t('task_special3'),
				link: `https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`,
				text: "70 000",
				width: 38,
				height: 38,
				status: user.frens.length >= 7 ? 'done' : 'undone',
			}].map((item) => (
				<a
					key={item.title}
					href={item.link}
					className="box box_task"
				>
					<div className="box_icon" style={{ width: 54, height: 54 }}>
						<img
							src={item.icon}
							width={item.width}
							height={item.height}
							alt="icon"
						/>
					</div>
					<div className="box_info">
						<div className="box_title" style={item.text ? {} : { margin: 0 }}>{item.title}</div>
						{item.text && (
							<div className="box_subtitle" style={{ fontWeight: '900', color: '#3262f1' }}>{`+ ${item.text}`}</div>
						)}
					</div>
					<div className="box_icon_after">
						<img
							src={item.status === 'done' ? "./img/icons/check.svg" : "./img/icons/chevron_right.svg"}
							width={20}
							height={20}
							alt="status"
						/>
					</div>
				</a>
			))}
		</div>
	);
};

export default Tasks;
