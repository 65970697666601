import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './components/UI/Button';

const Intro = ({
	user,
	userPlatform,
	onModal,
}) => {
	const { t } = useTranslation();
	const [activeSection, setActiveSection] = useState(1);

	return (
		<div id="intro" className="container" style={{ padding: 0 }}>
			{[{
				id: 1,
				icon: userPlatform === 'ios' ? "img/icons/appstore.svg" : "img/icons/android.svg",
			}, {
				id: 2,
				icon: "img/icons/link.svg",
			}].map((item, index) => (
				<React.Fragment key={item.id}>
					{activeSection === (index + 1) && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-evenly',
								padding: 18,
							}}
						>
							<div>
								<img
									src="img/icons/intro.svg"
									alt="intro"
								/>
							</div>
							{item.id === 1 && (
								<div className="box_title" style={{ fontSize: '20px', lineHeight: '30px' }}>{t('intro_title1')}</div>
							)}
							<img
								src={item.icon}
								width={200}
								height={200}
								style={{ margin: '0 auto' }}
								alt="fren"
							/>
							{item.id === 1 && (
								<div className="box_title" style={{ fontSize: '20px', lineHeight: '30px' }}>{t('intro_title2')}</div>
							)}
							{item.id === 2 && (
								<div className="box_title" style={{ fontSize: '20px', lineHeight: '30px' }}>{t('intro_title3')}</div>
							)}
							{item.id === 1 ? (
								<div className="box_footer">
									<Button
										style={{ margin: 0, height: 60, fontSize: 20 }}
										onClick={() => onModal('introReturn', { userPlatform, setActiveSection })}
										title="download application"
										stretched
									>{t('intro_btn1')}</Button>
									<Button
										style={{ margin: 0, height: 60, fontSize: 20, color: 'var(--primary)', background: 'transparent' }}
										onClick={() => setActiveSection(2)}
										title="next"
										stretched
									>{t('intro_btn2')}</Button>
								</div>
							) : (
								<div className="box_footer">
									<Button
										href={`https://deep.gigico.in?tg_id=${user?.id}`}
										style={{ margin: 0, height: 60, fontSize: 20 }}
										onClick={() => setTimeout(() => { document.location.href = "/" }, 3000)}
										title="link application"
										stretched
									>{t('intro_btn3')}</Button>
									<Button
										style={{ margin: 0, height: 60, fontSize: 20, color: 'var(--primary)', background: 'transparent' }}
										onClick={() => setActiveSection(1)}
										title="back"
										stretched
									>{t('intro_btn4')}</Button>
								</div>
							)}
						</div>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default Intro;
