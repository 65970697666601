const server = 'https://app.gigico.in/api';

async function serverRequest(method, url, params, headers) {
	const json = {
		method,
		headers,
	}
	if (Object.keys(params).length > 0) {
		json['body'] = JSON.stringify(params);
		json['headers']['Content-Type'] = 'application/json';
	} else {
		json['body'] = JSON.stringify({});
		json['headers']['Content-Type'] = 'application/json';
	}
	let activeUrl = `${server}${url}`;
	if (url.indexOf('http') !== -1) {
		activeUrl = url;
	}
	return fetch(activeUrl, json).catch((error) => {
		return { type: 'error', message: error.toString() }
	});
}

export default function api(method, url, params = {}, headers = {}) {
	return new Promise((resolve, reject) => {
		serverRequest(method, url, params, headers).then(async (response) => {
			let res = response;
			if (response.status === 401) {
				res = await response.text();
				reject(res);
			} else if (response.type === 'error') {
				reject(response.message);
			} else if (response) {
				res = await response.json();
				if (res.detail) {
					reject(res.detail);
				} else {
					resolve(res);
				}
			} else {
				reject(res);
			}
		});
	});
}
