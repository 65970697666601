// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_block__\\+E7Hl {
    background: var(--secondary);
    padding: 6px;
    border-radius: 14px;
    margin-bottom: 16px;
}

.Tabs_options__nJD\\+v {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Tabs_option__bTSyp {
    cursor: pointer;
    text-align: center;
    border-radius: 12px;
    padding: 12px;
    font-weight: 600;
    font-size: 16px;
    color: var(--gray);
    width: 100%;
    text-transform: capitalize;
}

.Tabs_option__bTSyp.Tabs_active__bdmXC {
    box-shadow: 0 1px 4px 0 rgba(150, 150, 180, 0.5);
    background: var(--white);
    color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/Tabs/index.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,gDAAgD;IAChD,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":[".block {\n    background: var(--secondary);\n    padding: 6px;\n    border-radius: 14px;\n    margin-bottom: 16px;\n}\n\n.options {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.option {\n    cursor: pointer;\n    text-align: center;\n    border-radius: 12px;\n    padding: 12px;\n    font-weight: 600;\n    font-size: 16px;\n    color: var(--gray);\n    width: 100%;\n    text-transform: capitalize;\n}\n\n.option.active {\n    box-shadow: 0 1px 4px 0 rgba(150, 150, 180, 0.5);\n    background: var(--white);\n    color: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `Tabs_block__+E7Hl`,
	"options": `Tabs_options__nJD+v`,
	"option": `Tabs_option__bTSyp`,
	"active": `Tabs_active__bdmXC`
};
export default ___CSS_LOADER_EXPORT___;
