import { useTranslation } from 'react-i18next';
import Button from './components/UI/Button';

const NotFound = ({
	location,
}) => {
	const { t } = useTranslation();

	return (
		<div className="container">
			<div className="title">{t('not_found_title')}</div>
			<div className="box">
				<div className="box_info">
					<div className="box_title">{t('not_found_subtitle')}</div>
					<div className="box_subtitle" style={{ marginBottom: 12 }}>{location.pathname}</div>
					<Button
						to="/"
						stretched
					>{t('not_found_btn')}</Button>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
