import styles from "./index.module.css";

export default function Modal({
  onClickClose,
  title,
  icon,
  scroll,
  children,
}) {
  return (
    <div
      className={`${styles.block} modal`}
      style={scroll ? { flexDirection: 'column' } : {}}
    >
      <div
        className={styles["close-panel"]}
        onClick={onClickClose}
      />
      {scroll ? (
        <div className={styles.scroll}>
          <ModalContent
            title={title}
            icon={icon}
            children={children}
          />
        </div>
      ) : (
        <ModalContent
          title={title}
          icon={icon}
          children={children}
        />
      )}
    </div>
  );
};

const ModalContent = ({
  title,
  icon,
  children,
}) => {
  return (
    <div className={styles.inner}>
      {title && (
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {icon && (
            <img
              src={icon}
              width={28}
              height={28}
              alt={title}
            />
          )}
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};
