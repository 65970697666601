// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_block__T3F6V,
.Modal_close-panel__DqtFx {
    position: fixed;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Modal_block__T3F6V {
    background: rgba(20, 29, 36, 0.3);
}

.Modal_close-panel__DqtFx {
    margin: auto;
}

.Modal_inner__ij9Ul {
    position: absolute;
    background: var(--white);
    min-width: 200px;
    max-width: 330px;
    z-index: 103;
    border-radius: 20px;
}

.Modal_header__HPcNf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--secondary-hover);
}

.Modal_header__HPcNf img {
    padding: 6px;
    background: var(--secondary);
    border-radius: 10px;
}

.Modal_title__cuvT0 {
    display: flex;
    align-items: center;
    color: var(--primary);
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -.8px;
    line-height: 1.2;
}

.Modal_content__\\+UdKX {
    padding: 16px;
}

.Modal_scroll__cvTWB {
    overflow-y: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.Modal_scroll__cvTWB .Modal_inner__ij9Ul {
    position: relative;
}

.Modal_close__ZlAcz {
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/Modal/index.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,+CAA+C;AACnD;;AAEA;IACI,YAAY;IACZ,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ","sourcesContent":[".block,\n.close-panel {\n    position: fixed;\n    z-index: 102;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n}\n\n.block {\n    background: rgba(20, 29, 36, 0.3);\n}\n\n.close-panel {\n    margin: auto;\n}\n\n.inner {\n    position: absolute;\n    background: var(--white);\n    min-width: 200px;\n    max-width: 330px;\n    z-index: 103;\n    border-radius: 20px;\n}\n\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 16px;\n    border-bottom: 1px solid var(--secondary-hover);\n}\n\n.header img {\n    padding: 6px;\n    background: var(--secondary);\n    border-radius: 10px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    color: var(--primary);\n    font-size: 22px;\n    font-weight: 700;\n    letter-spacing: -.8px;\n    line-height: 1.2;\n}\n\n.content {\n    padding: 16px;\n}\n\n.scroll {\n    overflow-y: auto;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n.scroll .inner {\n    position: relative;\n}\n\n.close {\n    padding: 5px;\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `Modal_block__T3F6V`,
	"close-panel": `Modal_close-panel__DqtFx`,
	"inner": `Modal_inner__ij9Ul`,
	"header": `Modal_header__HPcNf`,
	"title": `Modal_title__cuvT0`,
	"content": `Modal_content__+UdKX`,
	"scroll": `Modal_scroll__cvTWB`,
	"close": `Modal_close__ZlAcz`
};
export default ___CSS_LOADER_EXPORT___;
