import {
	useTonAddress,
	useTonConnectModal,
} from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MainIcon } from './assets/icons/main.svg';

function formatAddress(address) {
	return `${address.slice(0, 6)}...${address.slice(-6)}`;
}

const Home = ({
	user,
}) => {
	const { t } = useTranslation();
	const { open } = useTonConnectModal();
	const userFriendlyAddress = useTonAddress();

	return (
		<div className="container" id="home">
			<div className="title">
				<span>{`${t('hi')}, ${user && user.first_name ? user.first_name : t('stepper')}`}</span>
				<span>
					<MainIcon
						width={34}
						height={34}
					/>
				</span>
			</div>
			<div className="page_title">{t('general')}</div>
			<a
				className="box box_task"
				href={`https://deep.gigico.in?tg_id=${user.id}`}
			>
				<div className="box_icon" style={{ width: 54, height: 54 }}>
					<img
						src='./img/icons/fren.svg'
						width={35}
						height={35}
						alt="icon"
					/>
				</div>
				<div className="box_info">
					<div className="box_title">
						{t('today_steps')}
					</div>
					<div className="box_subtitle" style={{ margin: 0 }}>{`~ ${user ? user.daily : '-'} $GIGI`}</div>
				</div>
				<div className="box_icon_after">
					<span>{user ? user.daily : '-'}</span>
					<span>
						<img
							className="sync"
							src='./img/icons/sync.svg'
							width={18}
							height={18}
							style={{ marginLeft: 4, position: 'relative', top: 1 }}
							alt="sync"
						/>
					</span>
				</div>
			</a>
			<div className="page_title">{t('wallet')}</div>
			<div className="box box_task" onClick={open} style={{ overflow: 'hidden' }}>
				<div className="box_icon" style={{ width: 54, height: 54 }}>
					<img
						src='./img/icons/wallet.svg'
						width={35}
						height={35}
						alt="wallet"
					/>
				</div>
				<div className="box_info">
					<div className="box_title" style={{ margin: 0 }}>{userFriendlyAddress ? formatAddress(userFriendlyAddress) : t('connect')}</div>
				</div>
			</div>
			<div className="box box_task">
				<div className="box_icon" style={{ width: 54, height: 54 }}>
					<img
						src='./img/icons/logo.svg'
						width={35}
						height={35}
						alt="icon"
					/>
				</div>
				<div className="box_info">
					<div className="box_title" style={{ margin: 0 }}>{`$GiGi ${t('сoins')}`}</div>
				</div>
				<div className="box_icon_after">
					{user ? user.balance : '-'}
				</div>
			</div>
			<div className="page_title">{t('challenges')}</div>
			<a
				href={`https://t.me/share/url?url=https://t.me/gigicoin_bot?start=${user?.id}&text=${t('ref_text')}`}
				className="box box_task"
			>
				<div className="box_icon" style={{ width: 54, height: 54 }}>
					<img
						src='./img/icons/invite.svg'
						width={35}
						height={35}
						alt="icon"
					/>
				</div>
				<div className="box_info">
					<div className="box_title" style={{ margin: 0 }}>{t('challenge_fren')}</div>
				</div>
				<div className="box_icon_after">
					<img
						src="./img/icons/chevron_right.svg"
						width={20}
						height={20}
						alt="chevron"
					/>
				</div>
			</a>
			{/* <div className="caption">{`made by GIGI Team ${process.env.REACT_APP_VERSION}`}</div> */}
		</div>
	);
};

export default Home;
