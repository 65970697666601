import { useTranslation } from 'react-i18next';
import { classNamesString } from "../../../functions/classNames";
import styles from "./index.module.css";

export default function Tabs({
  value,
  onClick,
  options,
  className,
  style,
}) {
	const { t } = useTranslation();

  return (
    <>
      <div
        className={classNamesString(
          className,
          styles.block,
        )}
        style={style}
      >
        <div className={styles.options}>
          {options.map((option) => (
            <div
              key={option}
              onClick={() => onClick(option)}
              className={classNamesString(
                styles.option,
                option === value && styles.active,
              )}
            >{t(option)}</div>
          ))}
        </div>
      </div>
    </>
  );
};
