import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { App } from './App';
import './i18n';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<TonConnectUIProvider
				manifestUrl="https://app.gigico.in/tonconnect-manifest.json"
			>
				<App />
			</TonConnectUIProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
