import { Link } from "react-router-dom";
import { classNamesString } from "../../../functions/classNames";
import styles from "./index.module.css";

export default function Button({
  mode = "primary",
  stretched = false,
  disabled = false,
  type,
  href,
  to,
  loading,
  onClick,
  className,
  style,
  title,
  children,
}) {
  return (
    <>
      {(href || to) ? (
        <>
        {href ? (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer nofollow"
            title={title ? title : children}
            className={classNamesString(
              className,
              styles.block,
              styles[`mode-${mode}`],
              stretched && styles.stretched,
            )}
            style={loading ? {
              ...style,
              opacity: 0.8,
              pointerEvents: 'none',
            } : { ...style }}
            onClick={onClick}
            disabled={disabled}
          >
            {children}
          </a>
        ) : (
          <Link
            to={to}
            title={title ? title : children}
            className={classNamesString(
              className,
              styles.block,
              styles[`mode-${mode}`],
              stretched && styles.stretched,
            )}
            style={loading ? {
              ...style,
              opacity: 0.8,
              pointerEvents: 'none',
            } : { ...style }}
            onClick={onClick}
            disabled={disabled}
          >
            {loading ? (
            <>
              <img
                className="loader"
                src="/img/icons/loader.png"
                width={18}
                height={18}
                alt="loader"
              />
              <span>{loading}</span>
            </>
          ) : children}
          </Link>
        )}
        </>
      ) : (
        <button
          type={type ? type : "submit"}
          title={title ? title : children}
          className={classNamesString(
            className,
            styles.block,
            styles[`mode-${mode}`],
            stretched && styles.stretched,
          )}
          style={loading ? {
            ...style,
            opacity: 0.8,
            pointerEvents: 'none',
            background: 'var(--secondary)',
          } : { ...style }}
          onClick={onClick}
          disabled={disabled}
        >
          {loading ? (
            <>
              <img
                className="loader"
                src="/img/icons/loader.png"
                width={18}
                height={18}
                alt="loader"
              />
              <span>{loading}</span>
            </>
          ) : children}
        </button>
      )}
    </>
  );
};
