export const resources = {
  en: {
    translation: {
      "share": "Share",
      "loading": "Loading",
      "success": "Success",
      "error": "Error",
      "tabbar_main": "Main",
      "tabbar_frens": "Frens",
      "tabbar_tasks": "Tasks",
      "tabbar_stats": "Stats",

      "intro_title1": "GiGicoin is a Telegram game where you get $GIGI tokens for walking",
      "intro_title2": "For now, telegram doesn't allow to track steps, that's why you need to install a GiGi Tracker app",
      "intro_title3": "For start, connect app to the bot and get real-time tokens for every step you take.",
      "intro_return_title": "Don't forget to go back to Telegram and connect the app",
      "intro_return_btn": "Ok",
      "intro_btn1": "Download tracker",
      "intro_btn2": "Already installed",
      "intro_btn3": "Link application",
      "intro_btn4": "Back",

      "general": "General",
      "hi": "Hi",
      "stepper": "Stepper",
      "today_steps": "Today Steps",
      "wallet": "Wallet",
      "connect": "Connect wallet",
      "сoins": "сoins",
      "challenges": "Challenges",
      "challenge_fren": "Challenge a fren",

      "frens": "Frens",
      "frens_title": "+10k $GIGI for each new friend",
      "frens_subtitle": "Invite your friends to compete with them!",
      "copied": "Copied",
      "copy_link": "Copy link",
      "rating": "Rating",
      "invite": "Invite friend",
      "steps": "steps",
      "user": "user",
      "ref_text": "Run with me and make money",

      "tasks": "Tasks",
      "special": "Special",
      "task_special1": "Invite 1 fren",
      "task_special2": "Invite 3 frens",
      "task_special3": "Invite 7 frens",

      "stats": "Stats",
      "badges": "Badges",
      "daily": "Daily",
      "walker": "Walker",
      "walker_text": "For 7 days activity",
      "runner": "Runner",
      "runner_text": "For 30 days activity",
      "runaway": "Runaway",
      "runaway_text": "For 90 days activity",
      "sprinter": "Sprinter",
      "sprinter_text": "For 10'000 steps",
      "rapid": "Rapid",
      "rapid_text": "For 100'000 steps",
      "marathoner": "Marathoner",
      "marathoner_text": "For 1'000'000 steps",
      "overall": "Overall",
      "your_progress": "Your progress",
      "not_enough_data": "Not enough data",

      "not_found_title": "Error 404",
      "not_found_subtitle": "Page not found",
      "not_found_btn": "Main menu"
    },
  },
  ru: {
    translation: {
      "share": "Поделиться",
      "loading": "Загрузка",
      "success": "Успешно",
      "error": "Ошибка",
      "tabbar_main": "Главная",
      "tabbar_frens": "Друзья",
      "tabbar_tasks": "Задания",
      "tabbar_stats": "Статистика",

      "intro_title1": "GiGicoin - это игра в Telegram, в которой вы получаете токены $GIGI за шаги.",
      "intro_title2": "На данный момент telegram не позволяет отслеживать шаги, поэтому вам нужно установить приложение GiGi Tracker.",
      "intro_title3": "Для начала подключите приложение к боту и получайте токены в реальном времени за каждый сделанный шаг.",
      "intro_return_title": "Не забудьте вернуться в Telegram и привязать приложение",
      "intro_return_btn": "Я понял(а)",
      "intro_btn1": "Скачать трекер",
      "intro_btn2": "Уже установлено",
      "intro_btn3": "Привязать приложение",
      "intro_btn4": "Назад",

      "general": "Главное",
      "hi": "Привет",
      "stepper": "Шагмен",
      "today_steps": "Шаги сегодня",
      "wallet": "Кошелек",
      "connect": "Подключить кошелек",
      "сoins": "токены",
      "challenges": "Челленджи",
      "challenge_fren": "Челлендж с другом",

      "frens": "Друзья",
      "frens_title": "+10к $GIGI за каждого нового друга",
      "frens_subtitle": "Приглашайте своих друзей, чтобы посоревноваться с ними!",
      "copied": "Скопировано",
      "copy_link": "Копировать",
      "rating": "Рейтинг",
      "invite": "Пригласи друга",
      "steps": "шагов",
      "user": "друг",
      "ref_text": "Беги со мной и зарабатывай деньги",

      "tasks": "Задания",
      "special": "Специальные",
      "task_special1": "Пригласи 1 друга",
      "task_special2": "Пригласи 3 друга",
      "task_special3": "Пригласи 7 друзей",

      "stats": "Статистика",
      "badges": "Бейджи",
      "daily": "Ежедневный бонус",
      "walker": "Гуляка",
      "walker_text": "За 7 дней активности",
      "runner": "Бегун",
      "runner_text": "За 30 дней активности",
      "runaway": "Гигачад",
      "runaway_text": "За 90 дней активности",
      "sprinter": "Спринтер",
      "sprinter_text": "За 10'000 шагов",
      "rapid": "Скоростной",
      "rapid_text": "За 100'000 шагов",
      "marathoner": "Марафонец",
      "marathoner_text": "За 1'000'000 шагов",
      "overall": "Общее",
      "your_progress": "Ваш прогрес",
      "not_enough_data": "Недостаточно данных",

      "not_found_title": "Ошибка 404",
      "not_found_subtitle": "Страница не найдена",
      "not_found_btn": "Главное меню"
    },
  },
};
